import React from 'react'
import { graphql } from 'gatsby';

import Layout from 'components/Layout'
import SEO from 'components/SEO';
import Cases from 'components/Cases';
import Hero from 'components/Hero';

import "styles/CasePageTemplate.scss";

const CasePage = ({ data, location }) => {
  const { post, cases } = data
  const { acf: { hero, title } } = post;
  const url = location.href ? location.href : '';

  return (
    <Layout>
      <SEO yoast={{ meta: post.yoast_meta }} lang="nl" pathname={url} />
      <div className="klantcase-overview-page">
        <Hero
          className="klantcase-overview-hero"
          fluidImage={hero.image.localFile.childImageSharp.fluid}
          column={false}
        />

        <Cases otherCases={cases} title={title} withButton={false} />
      </div>
    </Layout>
  )
}

export default CasePage

export const query = graphql`
  query CasePageByID($wordpress_id: Int!) {
    cases: allWordpressWpKlantcases(filter: { 
      status: { eq: "publish" }
    }) {
      edges {
        node {
          title
          path
          acf {
            thumbnail {
              localFile {
                childImageSharp {
                  fixed(quality: 100, width: 340, height: 318) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }

    post: wordpressPage(
      wordpress_id: { eq: $wordpress_id }
    ) {
      id
      wordpress_id
      title
      slug
      content
      yoast_meta {
        name
        content
        property
      }
      acf {
        hero {
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 99, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        
        title
    }
  }
  }
`
